import '@n8n/chat/style.css';
import { createChat } from '@n8n/chat';

export const loadN8nChatWidget = () => {
  console.log('Iniciando carga del widget n8n Chat');

  createChat({
    webhookUrl: 'https://n8n-f2z8.onrender.com/webhook/0bbb9257-92f1-4f97-92bc-27d1aafefd6f/chat', // Asegúrate de que esta URL sea correcta
    target: '#n8n-chat', // Asegúrate de que este elemento exista en tu HTML
    mode: 'window', // Puedes cambiar a 'fullscreen' si lo prefieres
    // showWelcomeScreen: true,
    defaultLanguage: 'es',
    initialMessages: [
      '¡Hola! 👋',
      '¿En qué te puedo ayudar?'
    ],
    i18n: {
		es: {
			title: 'Hola soy Mati! 👋',
			subtitle: "Estoy aquí para ayudarte 24/7.",
			footer: '',
			getStarted: 'Nueva conversación',
			inputPlaceholder: 'Escribe tu pregunta..',
		},
	},
  });
};
