import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import { PuntosContext } from './context/PuntosContext';
import './Header.css';

function Header() {
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [headerOpacity, setHeaderOpacity] = useState('opacity-100');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { totalPuntos } = useContext(PuntosContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY) {
        setHeaderOpacity('opacity-50');
      } else {
        setHeaderOpacity('opacity-100');
      }
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  useEffect(() => {
    const checkSession = async () => {
      const session = await supabase.auth.getSession();
      setIsAuthenticated(!!session);

      if (session) {
        // console.log("iniciado2");
        // fetchPuntos();
      }
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session);

      if (session) {
        // console.log("iniciado");
        // fetchPuntos();
      }
    });

    return () => {
      if (authListener) {
        // authListener.unsubscribe();
      }
    };
  }, []);

  const handleMouseEnter = () => {
    setHeaderOpacity('opacity-100');
  };

  const handleMenuClick = () => {
    setHeaderOpacity('opacity-100');
    setMenuActive(true);
  };

  const handleCloseDrawer = () => {
    const drawerToggle = document.getElementById('my-drawer-3');
    if (drawerToggle) {
      drawerToggle.checked = false;
    }

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
    
    setMenuActive(false);
  };

  const handleCloseDropdown = () => {
    // Removemos el foco del botón y del menú
    const dropdownButton = document.querySelector('.dropdown button');
    const dropdownMenu = document.querySelector('.dropdown-content');
    if (dropdownButton) dropdownButton.blur();
    if (dropdownMenu) dropdownMenu.style.display = 'none';
    setTimeout(() => {
      if (dropdownMenu) dropdownMenu.style.display = '';
    }, 100);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    navigate('/'); // Redirigir a la página principal después de cerrar sesión
  };


  return (
    <div
      className={`drawer drawer-end fixed top-0 left-0 right-0 z-50 background-header ${headerOpacity} ${menuActive ? 'drawer-active' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > prevScrollY) {
          setHeaderOpacity('opacity-50');
        } else {
          setHeaderOpacity('opacity-100');
        }
      }}
    >
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="w-full navbar z-10 flex justify-between items-center">
          <div className="flex items-center justify-start px-2 mx-2">
            <Link to="/" onClick={handleCloseDrawer} className="text-xl normal-case animate-slideDown">
              <img className="logo" src="/img/MatiMarket.png" alt="MatiMarket" />
            </Link>
          </div>
          <div className="flex-none lg:hidden">
            <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost" onClick={handleMenuClick}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </label>
          </div>
          <div className="hidden lg:flex items-center space-x-4 mr-30">
            <Link to="/" onClick={handleCloseDrawer} className={location.pathname === '/' ? 'active hover-underline' : 'hover-underline'}>Inicio</Link>
            <Link to="/productos" onClick={handleCloseDrawer} className={location.pathname === '/productos' ? 'active hover-underline' : 'hover-underline'}>Productos</Link>
            <Link to="/comprar-tickets" onClick={handleCloseDrawer} className={location.pathname === '/comprar-tickets' ? 'active hover-underline' : 'hover-underline'}>Tickets</Link>
            <Link to="/como-participar" onClick={handleCloseDrawer} className={location.pathname === '/como-participar' ? 'active hover-underline' : 'hover-underline'}>Cómo Participar</Link>
            {isAuthenticated ? (
              <>
                <div className="relative dropdown">
                  <div tabIndex="0" role="button" className="btn m-1 bg-header border-header">
                    <img className="btn-settings" src="/img/settings.png" alt="" />
                  </div>
                  <ul tabIndex="0" className="dropdown-content menu bg-header rounded-box z-50 w-52 p-2 shadow absolute right-0">
                    <li><Link to="/mis-datos" onClick={() => { handleCloseDrawer(); handleCloseDropdown(); }} className={location.pathname === '/mis-datos' ? 'activo-dropdown' : 'hover-underline'}>Mis Datos</Link></li>
                    <li><Link to="/mis-pedidos" onClick={() => { handleCloseDrawer(); handleCloseDropdown(); }} className={location.pathname === '/mis-pedidos' ? 'activo-dropdown' : 'hover-underline'}>Mis Pedidos</Link></li>
                    <li><Link to="/mis-referidos" onClick={() => { handleCloseDrawer(); handleCloseDropdown(); }} className={location.pathname === '/mis-referidos' ? 'activo-dropdown' : 'hover-underline'}>Mis Referidos</Link></li>
                    <li><Link to="/mis-puntos" onClick={() => { handleCloseDrawer(); handleCloseDropdown(); }} className={location.pathname === '/mis-puntos' ? 'activo-dropdown' : 'hover-underline'}><span className="points-display color-mm">Puntos: {totalPuntos}</span></Link></li>
                    <li onClick={() => { handleCloseDrawer(); handleCloseDropdown(); }} className="menu-logout"><button onClick={handleLogout}>Salir</button></li>
                  </ul>
                </div>
                {/* Mostrar puntos */}
                <Link to="/mis-puntos" onClick={handleCloseDrawer}>
                  <div className={`points-container ${totalPuntos > 0 ? 'visible' : ''}`}>
                    <img src="/img/maticoins.png" alt="Moneda" />
                    <span className="points-display">{totalPuntos}</span>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <Link to="/registrate" className={location.pathname === '/registrate' ? 'active hover-underline' : 'hover-underline'}>Regístrate</Link>
                <Link to="/ingresar" className={location.pathname === '/ingresar' ? 'active hover-underline' : 'hover-underline'}>Ingresar</Link>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
        <ul className="menu p-4 bg-header">
          <li><Link to="/" onClick={handleCloseDrawer} className={location.pathname === '/' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Inicio</Link></li>
          <li><Link to="/productos" onClick={handleCloseDrawer} className={location.pathname === '/productos' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Productos</Link></li>
          <li><Link to="/comprar-tickets" onClick={handleCloseDrawer} className={location.pathname === '/comprar-tickets' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Tickets</Link></li>
          <li><Link to="/como-participar" onClick={handleCloseDrawer} className={location.pathname === '/como-participar' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Cómo Participar</Link></li>
          {isAuthenticated ? (
            <>
              <li><Link to="/mis-datos" onClick={handleCloseDrawer} className={location.pathname === '/mis-datos' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Mis Datos</Link></li>
              <li><Link to="/mis-pedidos" onClick={handleCloseDrawer} className={location.pathname === '/mis-pedidos' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Mis Pedidos</Link></li>
              {/* <li><Link to="/mis-favoritos" onClick={handleCloseDrawer} className={location.pathname === '/mis-favoritos' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Mis Favoritos</Link></li> */}
              {/* <li><Link to="/mi-lista-de-deseos" onClick={handleCloseDrawer} className={location.pathname === '/mi-lista-de-deseos' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Mi Lista de Deseos</Link></li> */}
              <li><Link to="/mis-referidos" onClick={handleCloseDrawer} className={location.pathname === '/mis-referidos' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Mis Referidos</Link></li>
              <li><Link to="/mis-puntos" onClick={handleCloseDrawer} className={location.pathname === '/mis-puntos' ? 'active-mobile drawer-underline' : 'drawer-underline'}><span className="points-display color-mm">Puntos: {totalPuntos}</span></Link></li>
              <li  onClick={handleCloseDrawer} className="menu-logout"><button onClick={handleLogout}>Salir</button></li>
            </>
          ) : (
            <>
              <li><Link to="/registrate" onClick={handleCloseDrawer} className={location.pathname === '/registrate' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Regístrate</Link></li>
              <li><Link to="/ingresar" onClick={handleCloseDrawer} className={location.pathname === '/ingresar' ? 'active-mobile drawer-underline' : 'drawer-underline'}>Ingresar</Link></li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Header;
